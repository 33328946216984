// ** I18n Imports
import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import {initReactI18next} from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

const lngDetector = new LanguageDetector()
lngDetector.addDetector({
    name: 'customDetector',
    lng: window.location.href.split('/')
})

const Languages = ['uz', 'ru', 'en']

i18n

    // Enables the i18next backend
    .use(Backend)

    // Enable automatic language detection
    .use(LanguageDetector)

    // Enables the hook initialization module
    .use(initReactI18next)
    .init({
        load: 'languageOnly',
        cleanCode: true,
        nonExplicitSupportedLngs: false,
        //lng: localStorage.getItem('i18nextLng') || 'uz',
        backend: {
            /* translation file path */
            loadPath: `${process.env.PUBLIC_URL}/assets/data/locales/{{lng}}.json`
        },
        fallbackLng: 'uz',
        supported: ['uz', 'ru', 'en'],
        preload: ['uz', 'ru', 'en'],
        debug: false,
        whitelist: Languages,
        keySeparator: false,
        nsSeperator: false,
        react: {
            useSuspense: false
        },
        interpolation: {
            escapeValue: false,
            formatSeparator: ','
        },
        detection: {
            caches: ['cookie'],
            cookieSameSite: 'strict',
            lookupQuerystring: 'lang', // default is lng
            lookupCookie: 'next-i18next',
            order: ['localStorage', 'querystring', 'cookie', 'header'] // IMPORTANT
        }
    })

export default i18n

// ** Redux Imports
import {createSlice} from '@reduxjs/toolkit'

export const smsSlice = createSlice({
    name: 'sms',
    initialState: {
        sent: false,
        confrimed: true,
        isChange: false,
        eventTime: 0,
        phone: '',
        changePhone: '',
        code: ''
    },
    reducers: {
        handleSend: (state, action) => {
            state.sent = action.payload
        },
        setEventTime: (state, action) => {
            state.eventTime = action.payload
        },
        setConfrimedPhone: (state, action) => {
            state.confrimed = action.payload
        },
        setChangeState: (state, action) => {
            state.isChange = action.payload
        },
        setPhoneVal: (state, action) => {
            state.phone = action.payload
        },
        setChangePhoneVal: (state, action) => {
            state.changePhone = action.payload
        },
        setCodeVal: (state, action) => {
            state.code = action.payload
        }
    }
})

export const {
    handleSend,
    setEventTime,
    setConfrimedPhone,
    setChangeState,
    setPhoneVal,
    setChangePhoneVal,
    setCodeVal
} = smsSlice.actions

export default smsSlice.reducer
